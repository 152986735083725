export default {
  "settings": {
    "startup-options": {
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nertivia beim Systemstart ausführen"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um diese Einstellungen zu ändern, müssen Sie die Nertivia Desktop-App herunterladen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startoptionen für die Desktop-App"])}
    },
    "account": {
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Drive verknüpfen"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr Profil"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Informationen sind öffentlich"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten Sie Ihr Profil"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Drive erneut verknüpfen"])}
    },
    "delete-account": {
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto löschen"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Bots löschen"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Server verlassen/löschen"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Nachrichten in öffentlichen Servern"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind dabei, Ihr Konto zu löschen"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorraussetzungen"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was wird gelöscht"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was wird nicht gelöscht"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tragen Sie bei, indem Sie eine PR in der GitHub-Repository einreichen."])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzer"])}
    },
    "manage-bots": {
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehl hinzufügen"])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie Bot-Befehle hinzu, um Benutzer einfach darüber zu informieren, welche Befehle für Ihren Bot vorhanden sind"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladung kopieren"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token kopieren"])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot erstellen"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladung erstellen"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot löschen"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Bot-Benutzer, mit denen Sie mithilfe unserer API Ihre eigenen Bots erstellen können."])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot bearbeiten"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehle bearbeiten"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token verwalten"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token zurücksetzen"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token anzeigen"])}
    },
    "manage-emojis": {
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis hinzufügen"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie kostenlos Ihre eigenen hübschen Emojis hoch! Emojis dürfen nicht größer als 3 MB sein."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützte Datei-Typen sind: png, jpg, gif"])}
    },
    "notification": {
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungston"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macht ein cooles Geräusch, um Ihre Aufmerksamkeit zu erregen"])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push-Benachrichtigung"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie Push-Benachrichtigungen, auch wenn die App geschlossen ist."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie Ihre Benachrichtigungseinstellungen."])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen Sie Programme oder Spiele, die Sie ausführen, indem Sie unten Apps hinzufügen. Dies ersetzt Ihren benutzerdefinierten Status beim Öffnen eines Programms und wird beim Schließen des Programms zurückgesetzt."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Programmaktivitätsfunktion nutzen zu können, müssen Sie die Nertivia Desktop-App herunterladen."])}
    },
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto löschen"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzeröberfläche"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots verwalten"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis Verwalten"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmaktivität"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startoptionen"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfertige Features"])}
    },
    "interface": {
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farben"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie das Erscheinungsbild von Nertivia."])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOLED Dunkel"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halloween 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vordefinierte Farbthemen"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardfarben"])},
      "color-scheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbschema"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefiniertes CSS"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie unfertige oder experimentelle Funktionen."])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit sind keine unfertigen Funktionen verfügbar."])}
    }
  },
  "add-friend-popout": {
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge hinzu..."])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie einen Freund hinzu, indem Sie dessen Benutzernamen und Tag eingeben."])}
  },
  "add-server-popout": {
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Ihren eigenen Server. Benennen Sie zunächst Ihren Server."])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treten Sie einem Server bei, indem Sie einen Einladungscode eingeben."])},
    "tabs": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitreten"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server hinzufügen"])}
  },
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher?"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kopieren"])},
  "dashboard-tab": {
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenen Status setzen"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplett Aufgeholt!"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktnachrichten"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erkunden"])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freunde"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Freund"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizen"])}
  },
  "message-area": {
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht hinzufügen"])},
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben nicht die nötigen Rechte, um in diesem Kanal zu sprechen."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht verbunden"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre Nachricht ein"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück nach unten"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Nachrichten"])}
  },
  "message-context": {
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zitieren"])}
  },
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichere..."])},
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als gelesen markieren"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Einstellungen"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server verlassen"])}
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
      "admin": {
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert ALLE Berechtigungen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])}
      },
      "ban-user": {
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer kicken und Wiederbetreten verhindern"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bannen"])}
      },
      "kick-user": {
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Benutzer kicken"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kicken"])}
      },
      "manage-channels": {
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanäle bearbeiten oder löschen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanäle verwalten"])}
      },
      "manage-roles": {
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen bearbeiten oder löschen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen verwalten"])}
      },
      "send-messages": {
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten an Serverkanäle senden"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten senden"])}
      }
    },
    "tab-names": {
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebannte Benutzer"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server löschen"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanäle verwalten"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladungen verwalten"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigung verwalten"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen verwalten"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer verwalten"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server-Sichtbarkeit"])}
    },
    "manage-roles": {
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Rolle"])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardrolle"])},
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten Sie Ihre Rollen."])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Rolle erstellen"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle löschen"])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle ausblenden"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösche Rolle..."])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Rolle kann nicht gelöscht oder einem anderen Mitglied zugewiesen werden, da sie von einem Bot erstellt wurde. Entfernen Sie den Bot, um diese Rolle zu entfernen."])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Rolle kann nicht gelöscht oder ihre Zweisunge entfernt werden, da sie die Standardrolle ist, die auf jedes Mitglied angewendet wird."])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Rollenfarbe"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollenname"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Servereinstellungen"])}
    },
    "manage-channels": {
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal Name"])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal löschen"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösche Kanal..."])},
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten Sie Ihre Kanäle."])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist ein Standardkanal. Sie können den Standardkanal unter 'Allgemein' ändern. Standardkanäle können nicht gelöscht werden."])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Kanal erstellen"])},
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate Limit (Sekunden)"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML-Kanal (WIP)"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten Senden"])}
      },
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textkanal"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder von hier aus entbannen"])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entbannen"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entbanne..."])}
    },
    "manage-invites": {
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladung erstellen"])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladungen erstellen"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Einladung"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersteller"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Administratoren können alle Einladungen sehen."])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierten Link speichern"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzt"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer suchen"])}
    },
    "delete-server": {
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösche..."])},
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servernamen zur Bestätigung eingeben"])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie die Benachrichtigungseinstellungen."])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen ausschalten"])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen einschalten"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton stummschalten"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Einstellungen gelten nur für Sie und wirken sich nicht auf andere aus."])}
    }
  },
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Heute um ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gestern um ", _interpolate(_list(0))])}
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " schreibt..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Leute schreiben..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " und ", _interpolate(_list(2)), " schreiben..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " und ", _interpolate(_list(1)), " schreiben..."])}
  },
  "user-context": {
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bannen"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer:Tag kopieren"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen bearbeiten"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kicken"])},
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil ansehen"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal stumm schalten"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal nicht mehr stummschalten"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komprimieren"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen auf"])}
  },
  "presence": {
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesend"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschäftigt"])},
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Spielen"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Mitglieder (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Offline (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage annehmen"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freund hinzufügen"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abzeichen"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage abbrechen"])},
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinsame Freunde"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinsame Server"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beigetreten"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freund entfernen"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktnachricht senden"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendiert"])}
  },
  "connection": {
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifiziere..."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereit!"])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update verfügbar"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiert!"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
  },
  "messages": {
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist dem Server beigetreten!"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wurde gebannt"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wurde gekickt"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hat den Server verlassen"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnte keine Verbindung zum Server hergestellt werden."])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langsamer Modus"])}
  }
}